import React from "react";
import { Button, Container } from "@atoms";

const ButtonList = ({ buttons }) => {
  return (
    <Container
      variant="sm"
      className="flex flex-wrap items-center justify-center gap-6"
    >
      {buttons.map(b => (
        <Button key={b.uid} to={b.url}>
          {b.text}
        </Button>
      ))}
    </Container>
  );
};

export default ButtonList;
